import full01 from '../../../assets/images/fulls/01.jpg'
import full02 from '../../../assets/images/fulls/02.jpg'
import full03 from '../../../assets/images/fulls/03.jpg'
import full04 from '../../../assets/images/fulls/04.jpg'
import full05 from '../../../assets/images/fulls/05.jpg'
import full06 from '../../../assets/images/fulls/06.jpg'
import thumb02 from '../../../assets/images/thumbs/02.jpg'
import thumb01 from '../../../assets/images/thumbs/01.jpg'
import thumb03 from '../../../assets/images/thumbs/03.jpg'
import thumb04 from '../../../assets/images/thumbs/04.jpg'
import thumb05 from '../../../assets/images/thumbs/05.jpg'
import thumb06 from '../../../assets/images/thumbs/06.jpg'


export const DEFAULT_IMAGES = [
  {
    id: '5',
    source: full05,
    thumbnail: thumb05,
    caption: 'Sudoku Auto Solver',
    descriptionOne: 'An alternative to Convolutional Neural Network solutions.',
    descriptionTwo: 'Using PuLP to create a convex optimization solver.',
    url: 'https://nbviewer.jupyter.org/github/ew398/Sudoku-Solver/blob/master/Sudoku_Solver%20%281%29.ipynb'
  },
  {
    id: '3',
    source: full03,
    thumbnail: thumb03,
    caption: 'Machine Learning Constructors',
    descriptionOne: 'Constructing supervised learning methods with NumPy',
    descriptionTwo: 'broadcasting. Currently a work in progress',
    url: 'https://google.com/'
  },
    {
      id: '1',
      source: full01,
      thumbnail: thumb01,
      caption: 'Personalized Spotify Analytics',
      descriptionOne: 'Pulling my listening data from Spotify\'s API to ',
      descriptionTwo: 'explore my tastes. Currently a work in progress',
      url: 'https://google.com/'
    },

    {
      id: '6',
      source: full06,
      thumbnail: thumb06,
      caption: 'Jane Street Puzzles',
      descriptionOne: 'Coding solutions to Jane Street\'s monthly technical puzzles.',
      descriptionTwo: 'Solutions have been uploaded, explanations to come July 27th',
      url: 'https://github.com/ew398/Jane-Street-Puzzles/blob/master/2014-01-Sum-of-Squares.py'
    }
]
