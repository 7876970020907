import React from 'react'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const HomeIndex = () => {
  const siteTitle = 'Eric Wu | Data Scientist'
  const siteDescription = 'Site description'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              Hello and welcome to my page!
              <br />

            </h2>
          </header>
          <p>
            I have always believed that learning to ask the right questions can drive exceptional insight. <br/>
            By finding the tools to frame our best questions, we can transpose curiousity into a more meaningful outcome. <br/>
            In the projects below, the aim is to find the right tools to formulate more in-depth understanding <br/>
             across topics of personal interest, ranging from puzzles to music. <br/>
            I hope you enjoy the insights and findings from these endeavors as much as I have.
          </p>
        </section>
        <section id="two">
          <h2>Personal Projects</h2>
          <Gallery />
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
