import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

const GalleryItem = ({id, source, thumbnail, caption, descriptionOne, descriptionTwo, url, position, toggleLightbox}) => {
    const onClick = useCallback((e) => {
        e.preventDefault()
        toggleLightbox(position)
    }, [position, toggleLightbox]);

    return (<article key={id} className="6u 12u$(xsmall) work-item">
                  <h3>{caption}</h3>
        <a
        className="image fit thumb"
        href={url}
        target = "_blank"
        // onClick={url}
        >
        <img src={thumbnail} />
        </a>

        <p>{descriptionOne}<br></br>
          {descriptionTwo}
        </p>
    </article>)
};

GalleryItem.displayName = 'GalleryItem'
GalleryItem.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  descriptionOne: PropTypes.string.isRequired,
  descriptionTwo: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  toggleLightbox: PropTypes.func.isRequired
}

export default GalleryItem
